
export default async (to, from, savedPosition) => {
      // This function is coming from here and it's the only thing that seems to work for correct scrolling
      // https://toor.co/blog/nuxtjs-smooth-scrolling-with-hash-links/
      // namely, it solves this issue:
      // https://github.com/nuxt/nuxt.js/issues/5359
      if (savedPosition) {
        return savedPosition
      } else {
        let position = {}
        if (to.matched.length < 2) {
          position = { x: 0, y: 0 }
        } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
          position = { x: 0, y: 0 }
        }
        if (to.hash) {
          const findEl = async (hash, x) => {
            return document.querySelector(hash) ||
              new Promise((resolve, reject) => {
                if (x > 50) {
                  return resolve()
                }
                setTimeout(() => { resolve(findEl(hash, ++x || 1)) }, 100)
              })
          }

          let el = await findEl(to.hash);
          console.log('scroll', el);
          if ('scrollBehavior' in document.documentElement.style) {
            return window.scrollTo({ top: el.offsetTop, behavior: 'smooth' })
          } else {
            return window.scrollTo(0, el.offsetTop)
          }
        }
        return position
      }
    }
