<template>
  <nuxt />
</template>

<script>
export default {

}
</script>

<style lang="stylus">
</style>
